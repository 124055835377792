// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { SEIGE_NUMBER, SEIGE_MULTIPLY, SEIGE_TITLE } from 'constants/constants';

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: auto;
  justify-content: center;
  display: flex;
`;

const TitleContainer = styled.div`
  position: absolute;
  top: 180px;
  @media (max-width: 767px) {
    top: 150px;
  }
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #fcfcfd;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
  color: #fcfcfd;
`;

const Board = styled.div`
  background: #23262f;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;
  height: auto;
  max-height: 450px;
  padding: 15px;
  overflow-y: scroll;

  position: absolute;
  top: 350px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHeader = styled.th`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #fcfcfd;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
`;

const TableRow = styled.tr`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TableContent = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #fcfcfd;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
`;

const TableHeaderRow = styled.tr`
  border-bottom: white 1px solid;
`;

// CSV TO JSON : https://csvjson.com/csv2json
const winners = [
  {
    attendee: '0xdb883c1abc797fbc5da6bc1f9c614a653b439222',
    camp: 'TRICKY',
    klay: 8,
  },
  {
    attendee: '0x7bebb17519f99800606bacd8c7b90be22b161c87',
    camp: 'TYRANNO',
    klay: 3,
  },
  {
    attendee: '0xe89138ecadbe5263128802a4ef96834965dd01c8',
    camp: 'TYRANNO',
    klay: 70,
  },
  {
    attendee: '0x6494a52e67fabec53fca683503203416097fb7d7',
    camp: 'TYRANNO',
    klay: 15,
  },
  {
    attendee: '0x63a759634ddab32eb4447d1a3fd49a10ddeb51c5',
    camp: 'BRACHY',
    klay: 11,
  },
  {
    attendee: '0x5002bf8062316ffb9ce26445aba750534876b99e',
    camp: 'BRACHY',
    klay: 125,
  },
  {
    attendee: '0x19b30a77abcce9d649f2d72a51d5918fbe98b7ff',
    camp: 'TRICKY',
    klay: 32,
  },
  {
    attendee: '0xfce520c573fdd900cf3ba91d884bbdc0f833de62',
    camp: 'TYRANNO',
    klay: 3,
  },
  {
    attendee: '0x6938ad8ef3ce66853f0e0c4bad8884342a3d1999',
    camp: 'TYRANNO',
    klay: 3,
  },
  {
    attendee: '0x8b4067db5c76fac0ccbc0c588b64243e5454167c',
    camp: 'TYRANNO',
    klay: 3,
  },
  {
    attendee: '0x13e519bbe9354f29ce1f106602ddcb522b90b375',
    camp: 'TYRANNO',
    klay: 5,
  },
  {
    attendee: '0xbb8b30fc805e3f9615891f314703eeaf540121fa',
    camp: 'TYRANNO',
    klay: 53,
  },
  {
    attendee: '0x8cd4c06262bade10d2869bd3034c81d4e5442d64',
    camp: 'TYRANNO',
    klay: 82,
  },
];

const SeigeBoard = () => {
  // const kaikasAddress =
  //   localStorage.getItem('kaikasAddress') &&
  //   localStorage.getItem('kaikasAddress').toLowerCase();

  // const [title, setTitle] = React.useState(null);
  // const [winners, setWinners] = React.useState(null);
  // const [sum, setSum] = React.useState(null);
  // const [klay, setKlay] = React.useState(null);

  // React.useEffect(() => {
  //   const getBoardData = async () => {
  //     const params = {
  //       number: SEIGE_NUMBER,
  //       camp: 'TYRANNO',
  //     };
  //     const config = {
  //       params: params,
  //     };
  //     const response = await axios.get('seige/board', config);
  //     console.log(response.data);
  //     setTitle(response.data.title);
  //     setWinners(response.data.winners);
  //     setSum(response.data.sum);
  //     setKlay(response.data.klay);
  //   };

  //   getBoardData();
  // }, [setTitle, setWinners, setSum, setKlay]);

  return true ? (
    <Root>
      <TitleContainer>
        <Subtitle>- SIEGE BATTLE {SEIGE_NUMBER}-</Subtitle>
        <Title>{SEIGE_TITLE}</Title>
      </TitleContainer>
      <Board>
        <Table>
          <thead>
            <TableHeaderRow>
              <TableHeader>참가자</TableHeader>
              {/* <TableHeader>참여 kp</TableHeader> */}
              <TableHeader>보상 (Klay)</TableHeader>
            </TableHeaderRow>
          </thead>
          <div style={{ marginTop: '20px' }}></div>
          <tbody>
            {winners?.map((winner, index) => (
              <TableRow key={index}>
                <TableContent>{winner?.attendee}</TableContent>
                {/* <TableContent>{winner?.kp}</TableContent> */}
                <TableContent>
                  {parseInt(SEIGE_MULTIPLY * winner?.klay)}
                </TableContent>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Board>
    </Root>
  ) : null;
};

export default SeigeBoard;

